<template>
  <div class="my-account-personal-info">
    <HeroImage :bg-image="'images/2-service-pages-hero.jpg'" :has-purple-bg="false">
    </HeroImage>
    <section class="over-hero">
      <div class="container is-fullhd">
        <div class="my-account-wrap">
          <div class="columns content-my-account-wrap">
            <clinic-menu></clinic-menu>
            <div class="column is-12 right-column">
              <h3 class="title is-4 pb-4 txt-c-mobile">Informaciones personales</h3>
              <div class="columns is-multiline">
                <div class="column is-6 mb-3">
                  <div class="field">
                    <input-text :placeholder="'Nombre*'" v-model="userInfo.first_name" :name="'first_name'"></input-text>
                  </div>
                </div>
                <div class="column is-6 mb-3">
                  <div class="field">
                    <input-text :placeholder="'Apellidos*'" v-model="userInfo.last_name" :name="'last_name'"></input-text>
                  </div>
                </div>

                <div class="column is-6 mb-3">
                  <div class="field">
                    <input-text :placeholder="'Email*'" v-model="userInfo.email" :name="'email'"></input-text>
                  </div>
                </div>
                <!-- <div class="column is-6 mb-3">
                  <div class="field">
                    <input-text :placeholder="'Teléfono'" v-model="phone" :name="'phone'"></input-text>
                  </div>
                </div> -->

                <div class="column is-6 mb-3">
                  <div class="field">
                    <input-text :placeholder="'Fecha de nacimiento (YYYY-MM-DD)'" v-model="clinicInfo.date_of_birth" :name="'date_of_birth'"></input-text>
                  </div>
                </div>
                <!--<div class="column is-6 mb-3">
                  <div class="field">
                    <input-text :placeholder="'País ????'" v-model="city" :name="'city'"></input-text>
                  </div>
                </div> -->

                <div class="column is-6 mb-3">
                  <div class="field">
                    <input-text :placeholder="'Razón Social'" v-model="clinicInfo.company_name" :name="'company_name'"></input-text>
                  </div>
                </div>
                <div class="column is-6 mb-3">
                  <div class="field">
                    <input-text :placeholder="'NIF'" v-model="clinicInfo.tax_id" :name="'tax_id'"></input-text>
                  </div>
                </div>

                <!-- <div class="column is-6 is-offset-6 mb-3">
                  <button class="button is-primary is-fullwidth is-medium" @click="goTo('/checkout/oreder-completed')">Guardar</button>
                </div> -->
              </div>

              <!-- <hr>

              <h3 class="title is-4 pb-4 txt-c-mobile">Cambiar contraseña</h3>
              <div class="columns is-multiline">
                <div class="column is-6 mb-3">
                  <div class="field">
                    <input-text :placeholder="'Contraseña actual'" v-model="email" :name="'email'"></input-text>
                  </div>
                </div>
                <div class="column is-6 mb-3"></div>
                <div class="column is-6 mb-3">
                  <div class="field">
                    <input-text :placeholder="'Nueva contraseña'" v-model="email" :name="'email'"></input-text>
                  </div>
                </div>
                <div class="column is-6 mb-3">
                  <div class="field">
                    <input-text :placeholder="'Repetir la nueva contraseña'" v-model="email" :name="'email'"></input-text>
                  </div>
                </div>
                <div class="column is-6 is-offset-6 mb-3">
                  <button class="button is-primary is-fullwidth is-medium" @click="goTo('/checkout/oreder-completed')">Guardar</button>
                </div>
              </div>

              <hr> -->

              <h3 class="title is-4 pb-4 txt-c-mobile">Consentimientos</h3>
              <div class="columns is-multiline">
                <div class="column is-12 mb-3">
                  <div class="field">
                    <label class="checkbox">
                      <input type="checkbox" v-model="userInfo.newsletter_accepted">
                      Quiero recibir la newsletter de STLab
                    </label>
                  </div>
                </div>
                <div class="column is-12 mb-3">
                  <div class="field">
                    <label class="checkbox">
                      <input type="checkbox" v-model="userInfo.marketing_accepted">
                      Doy mi consentimiento para el uso de mis datos con fines de marketing y para recibir ofertas y promociones.
                    </label>
                  </div>
                </div>
              </div>
              <div class="columns is-multiline">
                <div class="column is-6 is-offset-6 mb-3">
                  <button class="button is-primary is-fullwidth is-medium" @click="updateInfo()">Guardar</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer></Footer>
  </div>
</template>

<script>
import ClinicMenu from '@/components/Clinic/ClinicMenu.vue'
import HeroImage from '@/components/HeroImage.vue'
import Footer from '@/components/Footer.vue'
import InputText from '@/components/FormElements/InputText.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'ClinicPersonalInfo',
  components: {
    ClinicMenu,
    HeroImage,
    Footer,
    InputText
  },
  data () {
    return {
      userInfo: {
        first_name: '',
        last_name: '',
        email: '',
        terms_of_service_accepted: false,
        marketing_accepted: false,
        newsletter_accepted: false
      },
      clinicInfo: {
        date_of_birth: '',
        tax_id: '',
        company_name: ''
      },
      phone: '',
      city: ''
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/user',
      clinic: 'user/clinic'
    })
  },
  methods: {
    logout () {
      const self = this
      this.$store.dispatch('user/logOut', {
        cb: () => {
          self.goTo('/')
        },
        cbError: () => {
          self.$store.commit({
            type: 'application/ADD_FEEDBACK',
            feedback: {
              type: 'error',
              message: 'Error on the log out'
            }
          })
        }
      })
    },
    updateUser () {
      const self = this
      this.$store.dispatch('user/updateUser', {
        params: this.userInfo,
        cb: () => {
          self.$store.commit({
            type: 'application/ADD_FEEDBACK',
            feedback: {
              type: 'success',
              message: 'Update completed'
            }
          })
        },
        cbError: (error) => {
          self.$store.commit({
            type: 'application/ADD_FEEDBACK',
            feedback: {
              type: 'error',
              message: error
            }
          })
        }
      })
    },
    updateClinic () {
      const self = this
      this.$store.dispatch('clinic/updateClinic', {
        clinicId: this.clinic.id,
        params: this.clinicInfo,
        cb: (response) => {
          console.log(response)
          self.$store.commit({
            type: 'user/SET_CLINIC_INFO',
            clinic: response
          })
        },
        cbError: (error) => {
          self.$store.commit({
            type: 'application/ADD_FEEDBACK',
            feedback: {
              type: 'error',
              message: error
            }
          })
        }
      })
    },
    updateInfo () {
      this.updateUser()
      this.updateClinic()
    },
    goTo (path) {
      this.$router.push({ path: path })
    },
    setLocalData () {
      this.userInfo = JSON.parse(JSON.stringify(this.user))
      this.clinicInfo = JSON.parse(JSON.stringify(this.clinic))
    }
  },
  created () {
    if (this.user.id === null || this.clinic.id === null) {
      const self = this
      this.$store.dispatch('user/getUser', {
        cb: () => {
          self.setLocalData()
        },
        cbError: (error) => {
          console.log(error)
          self.$store.commit({
            type: 'application/ADD_FEEDBACK',
            feedback: {
              type: 'error',
              message: error.response.data.error
            }
          })
        }
      })
    } else {
      this.setLocalData()
    }
  },
  mounted () {
    // console.log(this.clinicInfo)
  }
}
</script>

<style scoped lang="scss">
.my-account-personal-info{
  width: 100%;
  section{
    width: 100%;
    padding: 0px;
    &.over-hero{
      position: relative;
      margin-top: -430px;
      margin-bottom: 80px;
      z-index: 100;
    }
    .my-account-wrap{
      width: 100%;
      border-radius: 4px;
      background-color: #FFFFFF;
      box-shadow: 0 2px 24px 0 rgba(0,0,0,0.2);
      .content-my-account-wrap{
        flex-direction: column;
        padding: 0px;
        .right-column{
          padding: 80px;
        }
      }
    }
  }
}
</style>
